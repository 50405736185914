/*Breakpoints*/
/*Colors*/
/* Fonts */
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-HeavyItalic.woff2") format("woff2"), url("../public/fonts/CocoSharpL-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Coco Sharp";
  src: url("../public/fonts/CocoSharpL-Heavy.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-ExtraboldItalic.woff2") format("woff2"), url("../public/fonts/CocoSharpL-ExtraboldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Coco Sharp";
  src: url("../public/fonts/CocoSharpL-Extrabold.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-BoldItalic.woff") format("woff2"), url("../public/fonts/CocoSharpL-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-Bold.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-Italic.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-Regular.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-LightItalic.woff2") format("woff2"), url("../public/fonts/CocoSharpL-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Coco Sharp";
  src: url("../public/fonts/CocoSharpL-Light.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CocoSharp L";
  src: url("../public/fonts/CocoSharpL-ExtralightItalic.woff2") format("woff2"), url("../public/fonts/CocoSharpL-ExtralightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Coco Sharp";
  src: url("../public/fonts/CocoSharpL-Extralight.woff2") format("woff2"), url("../public/fonts/CocoSharpL-Extralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
/* General */
html {
  margin: 0 20px;
  scroll-behavior: smooth;
}
@media (max-width: 767.98px) {
  html {
    overflow-x: hidden;
  }
}
@media (min-width: 768px) {
  html {
    margin: 0 40px;
  }
}
@media (min-width: 992px) {
  html {
    margin: 0 60px;
  }
}
@media (min-width: 1400px) {
  html {
    margin: 0 46px;
  }
}

body {
  font-family: "CocoSharp L";
  overflow-x: hidden;
}
@media (min-width: 1200px) {
  body {
    max-width: 1400px;
    margin: auto;
  }
}

.c-black {
  color: #0C0C0C;
}

h1 {
  font-size: 40px;
  line-height: 46px;
}
@media (min-width: 768px) {
  h1 {
    font-size: 50px;
    line-height: 64px;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 64px;
    line-height: 74px;
  }
}

h2 {
  font-size: 30px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 40px;
  }
}

h3 {
  font-size: 24px;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-size: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 24px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.rounded-30 {
  border-radius: 30px !important;
}

.nav-active {
  color: #467DD2 !important;
}

a.active {
  color: #467DD2;
}

.cursor-pointer {
  cursor: pointer !important;
}

p {
  font-size: 15px;
  font-family: "CocoSharp L";
  font-weight: normal;
}
@media (min-width: 768px) {
  p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  p {
    font-size: 20px;
    line-height: 28px;
  }
}

.small {
  font-size: 11px;
  line-height: 15px;
}
@media (min-width: 768px) {
  .small {
    font-size: 16px !important;
    line-height: 25px;
  }
}

.xsmall, .xsmall span {
  font-size: 12px !important;
}

.xxsmall, .xxsmall span {
  font-size: 10px !important;
}

.hide {
  opacity: 0;
  max-width: 0;
}

.show {
  opacity: 1 !important;
  max-width: auto;
}

.form-input {
  margin-bottom: 20px;
}
.form-input:last-of-type {
  margin-bottom: 0;
}

.form-check-input {
  width: 15px;
  height: 15px;
  border: 1px solid #0C0C0C;
}

input {
  width: 100%;
  border: 1px solid #001A64;
  border-radius: 45px;
  padding: 5px 25px;
  outline: none !important;
  color: rgba(12, 12, 12, 0.2);
  font-size: 16px;
}

input::-webkit-input-placeholder {
  color: rgba(12, 12, 12, 0.2);
}

input:-moz-placeholder {
  color: rgba(12, 12, 12, 0.2);
}

textarea {
  width: 100%;
  border: 1px solid #001A64;
  border-radius: 20px;
  padding: 10px 25px;
  outline: none !important;
  color: rgba(12, 12, 12, 0.2);
  font-size: 16px;
}

.form-select {
  border: 0 !important;
  outline: 0 !important;
  border-radius: 50px;
}

textarea::-webkit-input-placeholder {
  color: rgba(12, 12, 12, 0.2);
}

textarea::-moz-placeholder {
  color: rgba(12, 12, 12, 0.2);
}

figure {
  margin: 0 0 0 0;
}

.sm-text {
  font-size: 16px;
  color: #0C0C0C;
}

.bg-pink {
  background-color: #FCC6E9;
}

.bg-green {
  background-color: #69FFAD;
}

.bg-blue {
  background-color: #467DD2;
}

.border-l-pink {
  border-left: 21px solid #FCC6E9;
}

.border-l-green {
  border-left: 21px solid #69FFAD;
}

.border-l-blue {
  border-left: 21px solid #467DD2;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.gap-10 {
  gap: 10px;
}

/* Buttons */
.btn-principal {
  border: 1px solid #001A64;
  border-radius: 45px;
  background-color: #FFFFFF;
  color: #001A64;
  padding: 4px 20px;
  font-size: 17px;
  font-weight: normal;
  transition: all 0.2s ease;
}
@media (min-width: 768px) {
  .btn-principal {
    font-size: 18px;
    padding: 8px 24px;
  }
}
.btn-principal:hover {
  background-color: #001A64;
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .btn-principal:hover {
    background-color: #69FFAD;
    border: 1px solid #69FFAD;
    color: #001A64;
  }
}
.btn-secondary {
  border: 1px solid #001A64;
  border-radius: 45px;
  padding: 8px 20px;
  transition: all 0.2s ease;
  background-color: transparent;
  color: #001A64;
}
.btn-secondary:hover {
  background-color: #69FFAD;
  border: 1px solid #69FFAD;
}
@media (min-width: 992px) {
  .btn-secondary {
    font-size: 18px;
  }
}

.CookieConsent {
  width: 72.2% !important;
  border-radius: 20px 20px 0 0;
  padding: 6px 14px;
  font-size: 11px !important;
  margin-left: 20px;
}
@media (min-width: 992px) {
  .CookieConsent {
    margin-left: 20px;
    width: 96% !important;
    border-radius: 50px 50px 0 0;
    padding: 8px 40px;
    font-size: 12px !important;
  }
}
.CookieConsent a {
  text-decoration: underline;
}

#rcc-decline-button, #rcc-confirm-button {
  background-color: transparent !important;
  color: #001A64 !important;
  margin: 0 11px !important;
}

/* Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  z-index: 99;
}
@media (min-width: 768px) {
  .header {
    padding: 0 40px;
  }
}
@media (min-width: 992px) {
  .header {
    padding: 0 60px;
  }
}
@media (min-width: 1400px) {
  .header {
    padding: 0 46px;
  }
}
.header-pinned {
  padding: 40px 0;
}
@media (min-width: 992px) {
  .header-pinned {
    padding: 44px 0;
  }
}
.header-logoh {
  width: 60px;
}
@media (min-width: 1400px) {
  .header-logoh {
    width: 74px;
  }
}
.header-logoh img {
  width: 60px;
}

#logo-pinned, #logo-scroll {
  transition: all 0.2s ease;
  width: 122px;
}
@media (min-width: 768px) {
  #logo-pinned, #logo-scroll {
    width: 144px;
  }
}
@media (min-width: 992px) {
  #logo-pinned, #logo-scroll {
    width: 167px;
  }
}
#logo-pinned img, #logo-scroll img {
  width: 167px;
}

.logo-small {
  width: 60px !important;
}
@media (min-width: 1400px) {
  .logo-small {
    width: 74px !important;
  }
}
.logo-small img {
  width: 60px !important;
}

#logo-scroll {
  opacity: 0;
  width: 60px;
}
@media (min-width: 1400px) {
  #logo-scroll {
    width: 74px;
  }
}
#logo-scroll img {
  width: 60px;
}

.navigation #navbar-right {
  border: 1px solid #001A64;
  background-color: #FFFFFF;
  border-radius: 40px;
  padding: 8px;
  display: none !important;
}
@media (min-width: 768px) {
  .navigation #navbar-right {
    display: flex !important;
  }
}
.navigation #navbar-right li {
  font-family: "CocoSharp L";
  list-style-type: none;
  padding: 7px 18px 8px 18px;
  font-size: 18px;
  color: #001A64;
  border-radius: 20px;
  transition: all 0.2s ease;
}
.navigation #navbar-right li:hover {
  background-color: #69FFAD;
}
.navigation #navbar-mobile {
  display: block !important;
}
@media (min-width: 768px) {
  .navigation #navbar-mobile {
    display: none !important;
  }
}
.navigation #lang {
  border: 1px solid #001A64;
  background-color: #FFFFFF;
  border-radius: 40px;
  padding: 8px;
  display: none !important;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 16px;
  margin-bottom: 42px;
  z-index: 9 !important;
}
@media (min-width: 992px) {
  .navigation #lang {
    margin-left: 30px;
  }
}
@media (min-width: 1400px) {
  .navigation #lang {
    margin-left: 46px;
  }
}
@media (min-width: 768px) {
  .navigation #lang {
    display: flex !important;
  }
}
.navigation #lang li {
  font-family: "CocoSharp L";
  list-style-type: none;
  padding: 7px 18px 8px 18px;
  font-size: 18px;
  color: #001A64;
  border-radius: 20px;
  transition: all 0.2s ease;
}
.navigation #lang li:hover {
  background-color: #69FFAD;
}

.navbar-left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  text-transform: uppercase;
}

.menu-button {
  display: flex;
  column-gap: 1rem;
  color: var(--white);
  cursor: pointer;
}

.navbar-left span {
  font-weight: 700;
}

#navbar a:hover {
  color: var(--highlight);
}

#navbar a.active {
  color: #467DD2;
}

.full_menu {
  width: 70%;
  padding: 30px 30px 20px 30px;
  top: 100px;
  left: 20px;
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #001A64;
  border-radius: 15px;
  z-index: 98;
}

.full-menu-inner li, .full-menu-inner a {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  list-style: none;
  padding-bottom: 8px;
  color: #001A64;
}

.hidden {
  display: none;
}

.visible {
  display: flex;
}

.mob-navbar-right {
  display: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.wrapper_menu {
  width: 44px;
  height: 44px;
  border: 1px solid #001A64;
  background-color: #FFFFFF;
  border-radius: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: transform 630ms ease-out;
}

.wrapper_menu.open {
  background-color: #001A64;
}

.line_menu {
  background-color: #001A64;
  border-radius: 1px;
  width: 90%;
  margin: 2.5px auto;
  height: 1px;
}

.line_menu.open {
  background-color: #FFFFFF;
  transform: rotate(45deg);
}

.line_menu.open:nth-child(1) {
  transform: rotate(45deg) translate(6px, 4px);
}

.line_menu.open:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
}

/* WA button */
.wa {
  margin-bottom: 42px;
  margin-right: 16px;
  z-index: 9 !important;
}
.wa img {
  margin-left: 20px;
}
@media (min-width: 992px) {
  .wa {
    margin-right: 30px;
    z-index: 1 !important;
  }
}
@media (min-width: 1400px) {
  .wa {
    margin-right: 1vw;
  }
}

.wa-txt {
  display: none;
  transition: ease all 2s;
}

.wa-img:hover + .wa-txt {
  display: contents;
  font-weight: 600;
  color: #69FFAD;
  font-size: 20px;
  position: relative;
  z-index: 4;
}

/* Footer */
footer {
  background-color: #467DD2;
  border-radius: 15px;
  padding: 40px 20px;
  color: #FFFFFF;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  footer {
    padding: 60px 80px 40px 80px;
    border-radius: 30px;
  }
}
@media (min-width: 992px) {
  footer {
    padding: 30px 90px 30px 20px;
    border-radius: 40px;
  }
}
footer li {
  list-style: none;
  font-size: 15px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  footer li {
    font-size: 18px;
  }
}
footer p, footer a {
  font-size: 15px;
  margin-bottom: 0;
  word-wrap: break-word;
}
@media (min-width: 768px) {
  footer p, footer a {
    font-size: 18px;
  }
}

.footer-logo {
  width: 100%;
}
@media (min-width: 992px) {
  .footer-logo {
    width: 30%;
  }
}
.footer-logo-inner img {
  width: 120px;
}
@media (min-width: 768px) {
  .footer-logo-inner img {
    width: 130px;
  }
}
.footer-nav {
  width: 100%;
  margin-top: 80px;
}
.footer-nav li {
  list-style: none;
  font-size: 15px;
  line-height: 30px;
  padding-bottom: 8px;
  word-break: break-word;
}
@media (min-width: 768px) {
  .footer-nav li {
    font-size: 16px;
    line-height: 24px;
  }
}
.footer-nav-sm {
  margin-top: 38px;
  font-size: 11px;
  line-height: normal;
  position: relative;
  z-index: 2;
}
.footer-nav-sm p {
  font-size: 10px !important;
  line-height: 15px;
}
@media (min-width: 768px) {
  .footer-nav-sm {
    font-size: 16px;
    margin-top: 38px;
  }
}
.footer-nav-sm .credit {
  font-size: 11px;
}
.footer-nav-sm .credit:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .footer-nav-sm .credit {
    font-size: 14px;
  }
}
.footer-nav-col1 {
  width: 30%;
}
.footer-nav-col2 {
  width: 30%;
}
.footer-nav-col3 {
  width: 40%;
}
@media (min-width: 992px) {
  .footer-nav {
    width: 70%;
    margin-top: 0;
  }
}

/*Home*/
.home-hero-content {
  margin-top: 232px;
}
@media (min-width: 768px) {
  .home-hero-content {
    margin-top: 310px;
  }
}
.home-hero-content-mobile {
  display: flex;
}
@media (min-width: 768px) {
  .home-hero-content-mobile {
    display: none;
  }
}
.home-hero-txt {
  padding-right: 0;
}
@media (min-width: 992px) {
  .home-hero-txt {
    padding-right: 4rem;
  }
}
@media (min-width: 1200px) {
  .home-hero-txt {
    padding-right: 29px;
  }
}
@media (min-width: 1400px) {
  .home-hero-txt {
    padding-right: 100px;
  }
}
.home-hero-svg {
  width: 160px;
  margin-right: -50px;
  scale: 0;
}
@media (min-width: 768px) {
  .home-hero-svg {
    scale: 0.9;
    margin-right: -10px;
  }
}
@media (min-width: 992px) {
  .home-hero-svg {
    scale: 1;
    margin-right: -60px;
    width: 190px;
  }
}
@media (min-width: 1400px) {
  .home-hero-svg {
    margin-right: 0px;
    width: 190px;
  }
}
.home-hero-img {
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  margin-top: 6px;
  aspect-ratio: 1/0.81;
}
@media (min-width: 992px) {
  .home-hero-img {
    height: 100%;
    margin-top: 22px;
    aspect-ratio: 2.5714/1;
  }
}
.home-about {
  margin-top: 0px;
}
@media (min-width: 768px) {
  .home-about {
    margin-top: 122px;
  }
}
.home-about figure {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 210px 210px 0 0;
  margin: 0 0 0 0 !important;
  aspect-ratio: 0.7152/1;
}
.home-about img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-about-content {
  margin-top: 40px;
  padding-left: 0px;
}
@media (min-width: 768px) {
  .home-about-content {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 992px) {
  .home-about-content {
    margin-top: 0;
    padding-left: 5px;
  }
}
.home-about-txt {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .home-about-txt {
    margin-top: 35px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .home-about-txt {
    font-size: 20px;
  }
}
.home-estrategia {
  margin-top: 60px;
}
@media (min-width: 768px) {
  .home-estrategia {
    margin-top: 100px;
  }
}
@media (min-width: 992px) {
  .home-estrategia {
    margin-top: 120px;
  }
}
.home-estrategia-txt {
  margin-top: 45px;
}
.home-estrategia .small {
  font-size: 15px;
  line-height: 24px;
}
@media (min-width: 992px) {
  .home-estrategia .small {
    font-size: 16px !important;
    line-height: 25px;
  }
}
.home-cta {
  margin-top: 110px;
  border: 1px solid #001A64;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 64px;
  color: #001A64;
  cursor: pointer;
  transition: all 0.2s ease;
  display: none !important;
}
@media (min-width: 768px) {
  .home-cta {
    display: flex !important;
  }
}
.home-cta-heading {
  font-size: 48px;
}
@media (min-width: 992px) {
  .home-cta-heading {
    font-size: 80px;
  }
}
.home-cta img {
  max-width: 80px;
}
@media (min-width: 992px) {
  .home-cta img {
    max-width: 180px;
  }
}
.home-cta:hover {
  background-color: #FCC6E9;
}
.home-contact {
  margin-top: 80px;
}
@media (min-width: 768px) {
  .home-contact {
    margin-top: 110px;
    margin-bottom: 110px;
  }
}
.home-contact-img img {
  border-radius: 0 150px 0 0;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}
@media (min-width: 768px) {
  .home-contact-img img {
    border-radius: 0 200px 0 0;
  }
}
.home-contact-txt {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .home-contact-txt {
    margin-top: 80px;
  }
}
@media (min-width: 992px) {
  .home-contact-txt {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .home-contact-txt p {
    margin-bottom: 54px;
  }
}
.home-contact-txt span {
  font-size: 16px;
}
.home-contact-form {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .home-contact-form {
    margin-top: 20px;
  }
}
.home-quote {
  margin-top: 80px;
  text-align: center;
}
@media (min-width: 768px) {
  .home-quote {
    margin-top: 110px;
  }
}
.home-quote p {
  font-size: 18px;
}
@media (min-width: 768px) {
  .home-quote p {
    font-size: 22px;
  }
}
.home-numbers {
  margin-top: 77px;
  background-color: #F6F6F6;
  border-radius: 20px;
  padding: 48px 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .home-numbers {
    margin-top: 110px;
    padding: 72px 0;
  }
}
.home-numbers-countup {
  margin: 40px auto 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .home-numbers-countup {
    margin: 80px auto 0 auto;
  }
}
@media (min-width: 992px) {
  .home-numbers-countup {
    margin: 70px auto 0 auto;
  }
}
.home-numbers-countup h2 {
  font-size: 40px;
  line-height: 54px;
}
@media (min-width: 768px) {
  .home-numbers-countup h2 {
    font-size: 80px;
  }
}
@media (min-width: 992px) {
  .home-numbers-countup h2 {
    font-size: 80px;
    line-height: 70px;
  }
}
.home-numbers-countup h2 span {
  color: #69FFAD;
}
.home-numbers-countup-cuentas {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .home-numbers-countup-cuentas {
    margin-top: 80px;
  }
}
@media (min-width: 992px) {
  .home-numbers-countup-cuentas {
    margin-top: 0px;
  }
}
.home-numbers-countup p {
  margin-bottom: 0;
  font-size: 18px;
}
@media (min-width: 768px) {
  .home-numbers-countup p {
    margin-top: 20px;
    font-size: 20px;
  }
}
.home-numbers-btn {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .home-numbers-btn {
    margin-top: 120px;
  }
}
.home-image {
  margin-top: 80px;
}
@media (min-width: 768px) {
  .home-image {
    margin-top: 110px;
  }
}
.home-image img {
  width: 100%;
  overflow: hidden;
  border-radius: 0 440px 440px 0;
  object-fit: cover;
  aspect-ratio: 1.25/1;
}
@media (min-width: 992px) {
  .home-image img {
    aspect-ratio: 2.12/1;
  }
}
.home-slider {
  margin: 80px auto 0 auto;
}
@media (min-width: 768px) {
  .home-slider {
    margin: 110px auto 0 auto;
  }
}
.home-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.home-slider-nav {
  border: 1px solid #001A64;
  border-radius: 200px;
  padding: 2px 10px;
  height: 48px;
  width: 48px;
}
@media (min-width: 992px) {
  .home-slider-nav {
    height: 70px;
    width: 80px;
    padding: 10px 15px;
  }
}
.home-slider-wrapper {
  border-radius: 20px;
  margin: 0;
  overflow: hidden;
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
}
@media (min-width: 992px) {
  .home-slider-wrapper {
    margin: 0 30px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -khtml-border-radius: 50px;
  }
}
@media (min-width: 1200px) {
  .home-slider-wrapper {
    margin: 0 34px;
  }
}
.home-slider-inner {
  padding: 20px;
  font-size: 18px;
  line-height: 23px;
  height: 100%;
  min-width: 85%;
}
@media (min-width: 768px) {
  .home-slider-inner {
    font-size: 40px;
    line-height: 41px;
    padding: 65px;
  }
}
@media (min-width: 992px) {
  .home-slider-inner {
    padding: 40px 32px;
  }
}
.home-slider-inner p, .home-slider-inner span {
  font-size: 18px;
  line-height: 23px;
}
@media (min-width: 768px) {
  .home-slider-inner p, .home-slider-inner span {
    font-size: 40px;
    line-height: 41px;
  }
}
@media (min-width: 992px) {
  .home-slider-inner p, .home-slider-inner span {
    font-size: 26px;
  }
}
.home-slider-inner .author {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  margin-top: 82px;
}
@media (min-width: 992px) {
  .home-slider-inner .author {
    margin-top: 80px;
  }
}
.home-divisas {
  margin: 80px 0 40px 0;
  text-align: center;
}
@media (min-width: 992px) {
  .home-divisas {
    margin: 110px 0 80px 0;
  }
}
.home-divisas span {
  font-size: 11px;
  line-height: 15px;
  display: block;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .home-divisas span {
    margin: 20px 0;
    font-size: 12px;
    line-height: 16px;
  }
}
@media (min-width: 992px) {
  .home-divisas span {
    margin: 20px 0;
    font-size: 12px;
    line-height: 16px;
  }
}
.home-divisas-mobile {
  display: block;
}
@media (min-width: 768px) {
  .home-divisas-mobile {
    display: none;
  }
}
.home-mobile {
  display: block;
}
@media (min-width: 768px) {
  .home-mobile {
    display: none !important;
  }
}
.home-mobile-cta {
  border: 1px solid #001A64;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 18px 22px;
  color: #001A64;
  cursor: pointer;
  transition: all 0.2s ease;
}
.home-mobile-cta-heading {
  font-size: 30px;
}
.home-mobile-cta img {
  max-width: 40px;
}
.home-desktop {
  display: none !important;
}
@media (min-width: 768px) {
  .home-desktop {
    display: flex !important;
  }
}

.swiper-wrapper {
  padding-left: 2.5%;
}
@media (min-width: 992px) {
  .swiper-wrapper {
    padding-left: 3.5%;
  }
}

.swiper-pagination-d {
  font-size: 12px !important;
  padding-left: 1.5vw;
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%;
}

.active {
  opacity: 1;
}

.home-slider-nav {
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

#slider-prev {
  transform: translateX(0%);
  transition: all 0.5s ease;
  cursor: pointer;
}
#slider-prev:hover {
  transform: translateX(-10%);
}

#slider-next {
  transform: translateX(0%);
  transition: all 0.5s ease;
  cursor: pointer;
}
#slider-next:hover {
  transform: translateX(10%);
}

.swiper-slide {
  width: 80%;
}

.swiper-slide:nth-child(2n) {
  width: 80%;
}

.swiper-slide:nth-child(3n) {
  width: 80%;
}

/* About */
.about-hero {
  margin-top: 95px;
}
@media (min-width: 768px) {
  .about-hero {
    margin-top: 115px;
  }
}
@media (min-width: 992px) {
  .about-hero {
    margin-top: 166px;
  }
}
.about-hero figure {
  aspect-ratio: 2.5778/2.08;
  overflow: hidden;
  border-radius: 20px;
}
@media (min-width: 992px) {
  .about-hero figure {
    aspect-ratio: 2.5778/1;
  }
}
.about-hero figure img {
  width: 100%;
  object-fit: cover;
  margin-top: 0;
}
.about-intro {
  margin-top: 38px;
}
@media (min-width: 768px) {
  .about-intro {
    margin-top: 60px;
  }
}
.about-txt {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20x;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .about-txt {
    margin-top: 45px;
    font-size: 20px;
    line-height: 25px;
  }
}
.about-img img {
  max-width: 180px;
  width: 180px;
  object-fit: cover;
}
@media (min-width: 992px) {
  .about-img img {
    max-width: 304px;
    width: 304px;
  }
}
.about-img figure {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .about-img figure {
    margin-top: 0;
  }
}
.about-img figure svg {
  width: 160px;
  height: 160px;
}
@media (min-width: 768px) {
  .about-img figure svg {
    width: 200px;
    height: 200px;
  }
}
.about-values {
  margin: 80px auto 0 auto;
}
@media (min-width: 768px) {
  .about-values {
    margin: 110px auto 0 auto;
  }
}
@media (min-width: 992px) {
  .about-values {
    margin: 120px auto 0 auto;
  }
}
.about-values-mission {
  background-color: #467DD2;
  border-radius: 45px;
  padding: 50px 30px;
  text-align: center;
  padding: 60px 90px;
}
@media (min-width: 768px) {
  .about-values-mission {
    padding: 15px 30px;
    border-radius: 175px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .about-values-mission {
    padding: 30px 60px;
  }
}
.about-values-mission p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .about-values-mission-text {
    border-left: 1px solid #0C0C0C;
    padding: 10px 25px 10px 35px;
  }
}
@media (min-width: 992px) {
  .about-values-mission-text {
    border-left: 1px solid #0C0C0C;
    padding: 20px 50px 20px 70px;
  }
}
.about-values-vision {
  background-color: #FCC6E9;
  border-radius: 45px;
  padding: 50px 30px;
  text-align: center;
  margin-top: 19px;
}
@media (min-width: 768px) {
  .about-values-vision {
    padding: 15px 30px;
    border-radius: 175px;
    text-align: left;
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .about-values-vision {
    padding: 30px 60px;
  }
}
.about-values-vision p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .about-values-vision-text {
    border-left: 1px solid #0C0C0C;
    padding: 10px 25px 10px 35px;
  }
}
@media (min-width: 992px) {
  .about-values-vision-text {
    border-left: 1px solid #0C0C0C;
    padding: 20px 50px 20px 70px;
  }
}
.about-values-marquee {
  margin-top: 40px;
  padding-bottom: 10px;
  text-align: center;
}
@media (min-width: 768px) {
  .about-values-marquee {
    margin-top: 110px;
    text-align: left;
  }
}
.about-values-marquee-txt {
  padding-right: 56px;
}
.about-values-marquee-txt h3 {
  word-spacing: 56px;
  font-size: 18px;
}
@media (min-width: 768px) {
  .about-values-marquee-txt h3 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .about-values-marquee-txt h3 {
    font-size: 24px;
  }
}
.about-values-marquee {
  padding-left: 0px;
}
@media (min-width: 768px) {
  .about-values-marquee {
    padding-left: 30px;
  }
}
.about-parallax {
  margin-top: 69px;
}
@media (min-width: 768px) {
  .about-parallax {
    margin-top: 110px;
  }
}
.about-parallax img {
  width: 100%;
  overflow: hidden;
  border-radius: 440px 0 0 440px;
  object-fit: cover;
  aspect-ratio: 2.12/1.7;
}
@media (min-width: 992px) {
  .about-parallax img {
    aspect-ratio: 2.12/1;
  }
}
@media (min-width: 1400px) {
  .about-parallax img {
    aspect-ratio: 2.3/1;
  }
}
.about-brandbook {
  margin: 81px auto;
}
@media (min-width: 768px) {
  .about-brandbook {
    margin: 110px auto;
  }
}
@media (min-width: 768px) {
  .about-brandbook-btn {
    margin-top: 40px;
    margin-left: 46px;
  }
}
.about-brandbook-txt {
  padding-left: 0px;
  margin-top: 46px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .about-brandbook-txt {
    padding-left: 32px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .about-brandbook-txt {
    padding-left: 0;
  }
}

/*Estrategias*/
.estrategias-hero {
  margin-top: 95px;
}
@media (min-width: 768px) {
  .estrategias-hero {
    margin-top: 115px;
  }
}
@media (min-width: 992px) {
  .estrategias-hero {
    margin-top: 165px;
  }
}
.estrategias-hero figure {
  aspect-ratio: 2.5778/2.08;
  overflow: hidden;
  border-radius: 20px;
}
@media (min-width: 992px) {
  .estrategias-hero figure {
    aspect-ratio: 2.5778/1;
  }
}
.estrategias-hero figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 0;
}
.estrategias-intro {
  margin-top: 38px;
}
@media (min-width: 768px) {
  .estrategias-intro {
    margin-top: 60px;
  }
}
.estrategias-intro-btn {
  margin-top: 40px;
}
.estrategias-svg img {
  width: 100%;
}
.estrategias-contact {
  margin-top: 82px;
}
@media (min-width: 768px) {
  .estrategias-contact {
    margin-top: 110px;
  }
}
.estrategias-contact img {
  border-radius: 0 200px 0 200px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}
@media (min-width: 992px) {
  .estrategias-contact img {
    border-radius: 0 100px 0 100px;
  }
}
.estrategias-w {
  margin: 81px auto;
  border-radius: 40px 40px 0px 200px;
  background-color: #FCC6E9;
  overflow: hidden;
}
@media (min-width: 992px) {
  .estrategias-w {
    margin: 110px auto;
    border-radius: 20px 0 200px 40px;
  }
}
.estrategias-w-txt {
  padding: 40px 20px 23px 20px;
}
@media (min-width: 768px) {
  .estrategias-w-txt {
    padding: 30px;
  }
}
.estrategias-w-txt p {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .estrategias-w-txt p {
    margin-top: 40px;
  }
}
.estrategias-w-txt .small {
  font-size: 15px;
  line-height: 20px;
}
.estrategias-w-img {
  overflow: hidden;
}
.estrategias-w-img img {
  flex-shrink: 0;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .estrategias-w-img {
    scale: 1;
    height: auto;
    aspect-ratio: 2/1.8;
  }
}
@media (min-width: 992px) {
  .estrategias-w-img {
    aspect-ratio: 1/1.65;
  }
}
@media (min-width: 1200px) {
  .estrategias-w-img {
    aspect-ratio: 1/1;
  }
}
@media (min-width: 1400px) {
  .estrategias-w-img {
    aspect-ratio: 1.4/1.1;
  }
}

/* Contact */
.contact-intro {
  margin-top: 80px;
}
@media (min-width: 768px) {
  .contact-intro {
    margin-top: 140px;
  }
}
.contact-intro-svg {
  max-width: 205px;
}
.contact-intro h2 {
  margin-bottom: 40px;
}
.contact-intro h3 {
  line-height: 28px;
}
.contact-intro p {
  font-size: 16px;
  margin-bottom: 0;
}
.contact-intro li {
  list-style: none;
  color: #001A64;
  font-size: 18px;
  padding-bottom: 18px;
}
.contact-intro li:hover {
  color: #467DD2;
}
.contact-intro-inner {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .contact-intro-inner {
    margin-top: -20px;
  }
}
.contact-intro-inner .small {
  font-size: 15px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .contact-intro-inner .small {
    font-size: 16px;
    line-height: 25px;
  }
}
.contact-map {
  margin: 80px auto;
  border-radius: 20px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .contact-map {
    margin: 110px auto;
  }
}
.contact-socials {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .contact-socials {
    margin-bottom: 0px;
  }
}
.contact-socials li {
  list-style-type: none;
  font-size: 18px;
  color: #001A64;
  margin-bottom: 12px;
}
.contact-socials li:last-of-type {
  margin-bottom: 0px;
}
.contact-socials li:hover {
  color: #467DD2;
}

/*Login */
.login-wrapper {
  margin: auto;
}
.login-wrapper h3 {
  margin-bottom: 20px;
}
.login-wrapper .form-input {
  margin-bottom: 20px;
}

/* Estatuto de riesgo */
.estatuto-riesgo {
  margin: 248px auto 184px auto;
}

/* Calculadora */
.calculadora {
  margin: 80px auto 80px auto;
}
@media (min-width: 992px) {
  .calculadora {
    margin: 150px auto 80px auto;
  }
}
.calculadora-title {
  margin-top: 60px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .calculadora-title {
    margin-top: 0;
    margin-bottom: 92px;
  }
}
.calculadora .input-group {
  border: 1px solid #001A64;
  outline: none;
  border-radius: 50px;
}
.calculadora .input-group-text {
  border-radius: 50px;
  background-color: #FFFFFF;
}
.calculadora input {
  border: none;
  outline: none;
  border-radius: 50px;
}
.calculadora p {
  font-size: 16px;
}
.calculadora-results {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .calculadora-results {
    margin-top: 150px;
  }
}
.calculadora-badges {
  margin-top: 40px;
  text-align: center;
}
.calculadora-badges .wrapper {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .calculadora-badges .wrapper {
    margin-bottom: 0;
  }
}
.calculadora-badges .blue {
  background-color: #467DD2;
  border-radius: 80px;
  padding: 18px;
  min-width: 210px;
  font-size: 20px;
  margin-bottom: 2px;
}
@media (min-width: 992px) {
  .calculadora-badges .blue {
    margin-bottom: 18px;
    font-size: 26px;
    min-width: 165px;
  }
}
@media (min-width: 1200px) {
  .calculadora-badges .blue {
    min-width: 210px;
  }
}
.calculadora-badges .pink {
  background-color: #FCC6E9;
  border-radius: 80px;
  padding: 18px;
  min-width: 210px;
  font-size: 20px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .calculadora-badges .pink {
    font-size: 26px;
    min-width: 165px;
  }
}
@media (min-width: 1200px) {
  .calculadora-badges .pink {
    min-width: 210px;
  }
}
.calculadora-badges .green {
  background-color: #69FFAD;
  border-radius: 80px;
  padding: 18px;
  min-width: 210px;
  font-size: 20px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .calculadora-badges .green {
    font-size: 26px;
    min-width: 165px;
  }
}
@media (min-width: 1200px) {
  .calculadora-badges .green {
    min-width: 210px;
  }
}
.calculadora-badges .grey {
  background-color: #F6F6F6;
  border-radius: 80px;
  padding: 18px;
  min-width: 210px;
  font-size: 20px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .calculadora-badges .grey {
    font-size: 26px;
    min-width: 165px;
  }
}
@media (min-width: 1200px) {
  .calculadora-badges .grey {
    min-width: 210px;
  }
}
.calculadora table {
  text-align: center;
  font-size: 10px;
  overflow-x: scroll !important;
}
@media (min-width: 768px) {
  .calculadora table {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .calculadora table {
    font-size: 16px;
  }
}
.calculadora thead {
  padding: 20px;
}
@media (min-width: 768px) {
  .calculadora thead {
    padding: 40px;
  }
}
@media (min-width: 992px) {
  .calculadora thead {
    padding: 60px;
  }
}
.calculadora th {
  padding: 10px 10px;
  height: 60px;
}
@media (min-width: 768px) {
  .calculadora th {
    padding: 20px 40px;
    height: 70px;
  }
}
.calculadora tbody, .calculadora td, .calculadora tfoot, .calculadora th, .calculadora thead, .calculadora tr {
  vertical-align: middle;
}

/* Brandbook */
.brandbook {
  margin-bottom: 110px;
}
.brandbook-content {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .brandbook-content {
    margin-top: 136px;
  }
}
.brandbook-content p {
  margin-bottom: 10px;
}
.brandbook-content .small {
  font-size: 13px;
  line-height: 15px;
}
@media (min-width: 768px) {
  .brandbook-content .small {
    font-size: 16px;
    line-height: 24px;
  }
}
.brandbook h4 {
  font-size: 18px;
}
@media (min-width: 768px) {
  .brandbook h4 {
    margin-bottom: 30px;
    font-size: 20px;
  }
}
.brandbook li {
  list-style: none;
  margin-bottom: 20px;
  color: #001A64;
  font-size: 18px;
}
.brandbook li:hover {
  color: #467DD2;
}
.brandbook-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 4;
}
.brandbook .vision {
  border-radius: 0 200px 0 0;
}
.brandbook .look {
  border-radius: 0 0 0 0;
}
.brandbook-img {
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  margin-top: 15px;
}
@media (min-width: 768px) {
  .brandbook-img {
    height: 100%;
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .brandbook-img {
    height: 100%;
    margin-top: 40px;
  }
}

.btn-primary {
  background-color: #FFFFFF !important;
  color: #001A64 !important;
  padding: 0 !important;
  border: 0 !important;
  width: 100%;
}

.dropdown-menu {
  border: 0 !important;
  padding: 10px 0 !important;
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.dropdown-item {
  padding: 10px 0 !important;
  color: #001A64 !important;
}

.dropdown-toggle::after {
  background-image: url('data:image/svg+xml;utf8,<svg width="19" height="11" viewBox="0 0 19 11" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L9.5 10L18 1" stroke="%23001A64" fill="none" stroke-miterlimit="10" stroke-linecap="square"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 11px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  margin-left: 14em !important;
}

/* Legales */
.legals {
  margin: 150px auto 60px auto;
}
.legals ul li::marker {
  font-size: 10px;
}
.legals a {
  text-decoration: underline;
}
.legals p {
  font-size: 11px;
  line-height: 15px;
}
@media (min-width: 768px) {
  .legals p {
    font-size: 16px !important;
    line-height: 25px;
  }
}
.legals h5 {
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .legals h5 {
    font-size: 22px;
    line-height: 30px;
    text-transform: none;
  }
}

/*Spacing*/
.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-17 {
  margin-top: 17px;
}

.mt-20 {
  margin-top: 20px;
}

.mmt-20 {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .mmt-20 {
    margin-top: 0;
  }
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-md-35 {
    margin-top: 35px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-md-40 {
    margin-top: 40px;
  }
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-md-60 {
    margin-top: 60px;
  }
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-96 {
  margin-top: 96px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .mt-md-80 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .mt-lg-30 {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .mt-md-40 {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .mt-md-60 {
    margin-top: 60px;
  }
}

@media (min-width: 768px) {
  .mt-md-80 {
    margin-top: 80px;
  }
}

.mt-lg-80 {
  margin-top: 80px;
}

.mb-28 {
  margin-bottom: 28px;
}

@media (min-width: 992px) {
  .mb-lg-40 {
    margin-bottom: 40px;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-lg-50 {
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.m-auto {
  margin: auto;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pt-20 {
  padding-top: 20px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-80 {
  padding-top: 80px;
}

@media (min-width: 768px) {
  .pt-md-20 {
    padding-top: 20;
  }
}

@media (min-width: 992px) {
  .pt-lg-35 {
    padding-top: 35px;
  }
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

@media (min-width: 768px) {
  .pl-md-30 {
    padding-left: 30px;
  }
}

.pb-11 {
  padding-bottom: 11px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0px;
  }
}

.small-brandbook p {
  font-size: 13px;
  line-height: 15px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .small-brandbook p {
    font-size: 16px;
    line-height: 24px;
  }
}

