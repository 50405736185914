// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl xxl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @if not $n {
      @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
    }
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }
  
  // Minimum breakpoint width. Null for the smallest (first) breakpoint.
  //
  //    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    576px
  @function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }
  
  // Maximum breakpoint width.
  // The maximum value is reduced by 0.02px to work around the limitations of
  // `min-` and `max-` prefixes and viewports with fractional widths.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
  // See https://bugs.webkit.org/show_bug.cgi?id=178261
  //
  //    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    767.98px
  @function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $max: map-get($breakpoints, $name);
    @return if($max and $max > 0, $max - .02, null);
  }
  
  // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
  // Useful for making responsive utilities.
  //
  //    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    ""  (Returns a blank string)
  //    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    "-sm"
  @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
  }
  
  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  @mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($lower, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($upper, $breakpoints) {
        @content;
      }
    }
  }
  
  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  @mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min:  breakpoint-min($name, $breakpoints);
    $next: breakpoint-next($name, $breakpoints);
    $max:  breakpoint-max($next, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($name, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($next, $breakpoints) {
        @content;
      }
    }
  }
  

/*Breakpoints*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);



/*Colors*/
$darkblue: #001A64;
$pink: #FCC6E9;
$lightblue: #467DD2;
$green: #69FFAD;
$gray: #F6F6F6;
$black: #0C0C0C;
$white: #FFFFFF;

/* Fonts */
@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-HeavyItalic.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Sharp';
    src: url('../public/fonts/CocoSharpL-Heavy.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-ExtraboldItalic.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-ExtraboldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Sharp';
    src: url('../public/fonts/CocoSharpL-Extrabold.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-BoldItalic.woff') format('woff2'),
        url('../public/fonts/CocoSharpL-BoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-Bold.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-Italic.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-Regular.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-LightItalic.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Sharp';
    src: url('../public/fonts/CocoSharpL-Light.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CocoSharp L';
    src: url('../public/fonts/CocoSharpL-ExtralightItalic.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-ExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Sharp';
    src: url('../public/fonts/CocoSharpL-Extralight.woff2') format('woff2'),
        url('../public/fonts/CocoSharpL-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/* General */

html{
    margin: 0 20px;
    scroll-behavior: smooth;

    @include media-breakpoint-down(md){
        overflow-x: hidden;
    }

    @include media-breakpoint-up(md){
        margin: 0 40px;
    } 
    
    @include media-breakpoint-up(lg){
        margin: 0 60px;
    } 

    @include media-breakpoint-up(xxl){
        margin: 0 46px;
    } 
}

body{
    font-family: 'CocoSharp L';
    overflow-x: hidden;

    @include media-breakpoint-up(xl){
        max-width: 1400px;
        margin: auto;
    }
}

.c-black{
    color: $black;
}

h1{
    font-size: 40px;
    line-height: 46px;

    @include media-breakpoint-up(md){
        font-size: 50px;
        line-height: 64px;
    }

    @include media-breakpoint-up(lg){
        font-size: 64px;
        line-height: 74px;
    }
}

h2{
    font-size: 30px;

    @include media-breakpoint-up(lg){
        font-size: 40px;
    }

}

h3{
    font-size: 24px;
    margin-bottom: 0;

    @include media-breakpoint-up(lg){
        font-size: 24px;
    }

}

h4{
    font-size: 20px;

    @include media-breakpoint-up(lg){
        font-size: 24px;
    }

}


a{
    text-decoration: none;
    color: inherit;
}

.rounded-30{
    border-radius: 30px !important;
}

.nav-active{
    color: $lightblue !important;
}

a.active{
    color: $lightblue;
}

.cursor-pointer{
    cursor: pointer !important;
}

p {
    font-size: 15px;
    font-family: 'CocoSharp L';
    font-weight: normal;

    @include media-breakpoint-up(md){
        font-size: 18px;
    }

    @include media-breakpoint-up(lg){
        font-size: 20px;
        line-height: 28px;
    }
}

.small{
    font-size: 11px;
    line-height: 15px;

    @include media-breakpoint-up(md){
        font-size: 16px !important;
        line-height: 25px;
    }
}

.xsmall, .xsmall span{
    font-size: 12px !important;
}

.xxsmall, .xxsmall span{
    font-size: 10px !important;
}

.hide{
    opacity: 0;;
    max-width: 0;
}

.show{
    opacity: 1 !important;
    max-width: auto;
}

.form-input{
    margin-bottom: 20px;

    &:last-of-type{
        margin-bottom: 0;
    }
}

.form-check-input{
    width: 15px;
    height: 15px;
    border: 1px solid $black;
}

input {
    width: 100%;
    border: 1px solid $darkblue;
    border-radius:45px;
    padding: 5px 25px;
    outline: none !important;
    color: rgb(12, 12, 12, 0.2);
    font-size: 16px;
}

input::-webkit-input-placeholder{
    color: rgb(12, 12, 12, 0.2);
}
input:-moz-placeholder {
    color: rgb(12, 12, 12, 0.2);
}

textarea{
    width: 100%;
    border: 1px solid $darkblue;
    border-radius:20px;
    padding: 10px 25px;
    outline: none !important;
    color: rgb(12, 12, 12, 0.2);
    font-size: 16px;
}


.form-select{
    border: 0 !important;
    outline: 0 !important;
    border-radius: 50px ;

}

textarea::-webkit-input-placeholder{
    color: rgb(12, 12, 12, 0.2);
}
textarea::-moz-placeholder {
    color: rgb(12, 12, 12, 0.2);
}


figure{
    margin: 0 0 0 0;
}

.sm-text{
    font-size: 16px;  
    color: $black;  
}

.bg-pink{
    background-color: $pink;
}

.bg-green{
    background-color: $green;
}

.bg-blue{
    background-color: $lightblue;
}

.border-l-pink{
    border-left: 21px solid $pink;
}

.border-l-green{
    border-left: 21px solid $green;
}

.border-l-blue{
    border-left: 21px solid $lightblue;
}

.text-right{
    text-align: right;
}

.text-center{
    text-align: center;
}

.gap-10{
    gap: 10px;
}

/* Buttons */
.btn{
    &-principal{
        border: 1px solid $darkblue;
        border-radius: 45px;
        background-color: $white;
        color: $darkblue;
        padding: 4px 20px;
        font-size: 17px;
        font-weight: normal;
        transition: all 0.2s ease;

        @include media-breakpoint-up(md){
            font-size: 18px;
            padding: 8px 24px;
        }  

        &:hover{
            background-color: $darkblue;
            color: $white;

            @include media-breakpoint-up(md){
                background-color: $green;
                border: 1px solid $green;
                color: $darkblue;
            }    
        }
    }

    &-secondary{
        border: 1px solid $darkblue;
        border-radius: 45px;
        padding: 8px 20px;
        transition: all 0.2s ease;
        background-color: transparent;
        color: $darkblue;

        &:hover{
            background-color: $green;
            border: 1px solid $green;
        }

        @include media-breakpoint-up(lg){
            font-size: 18px;
        }
    }
}


.CookieConsent{
    width: 72.2% !important;
    border-radius: 20px 20px 0 0;
    padding: 6px 14px;
    font-size: 11px !important;
    margin-left: 20px;

    @include media-breakpoint-up(lg){
        margin-left: 20px;
        width: 96% !important;
        border-radius: 50px 50px 0 0;
        padding: 8px 40px;
        font-size: 12px !important;
    }

    a{
        text-decoration: underline;
    }


}

#rcc-decline-button, #rcc-confirm-button{
    background-color: transparent !important;
    color: $darkblue !important;
    margin: 0 11px !important;
}

/* Header */
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
    z-index: 99;

    @include media-breakpoint-up(md){
        padding: 0 40px;
    }
    
    @include media-breakpoint-up(lg){
        padding: 0 60px;
    }

    @include media-breakpoint-up(xxl){
        padding: 0 46px;
    }

    &-pinned{
        padding: 40px 0;

        @include media-breakpoint-up(lg){
            padding: 44px 0;
        }
    }

    &-logoh{
        width: 60px;

        @include media-breakpoint-up(xxl){
            width: 74px;
        }

        img{
            width: 60px;
        }
    }
}

#logo-pinned, #logo-scroll {
    transition: all .2s ease;
    width: 122px;

    @include media-breakpoint-up(md){
        width: 144px;
    }

    @include media-breakpoint-up(lg){
        width: 167px;
    }

    img{
        width: 167px;
    }
}

.logo-small{
    width: 60px !important;

    @include media-breakpoint-up(xxl){
        width: 74px !important;
    }

    img{
        width: 60px !important;
    } 
}

#logo-scroll{
    opacity: 0;;
    width: 60px;

    @include media-breakpoint-up(xxl){
        width: 74px;
    }

    img{
        width: 60px;
    }
}


.navigation{
    #navbar-right{
        border: 1px solid $darkblue;
        background-color: $white;
        border-radius: 40px;
        padding: 8px;
        display: none !important;

        @include media-breakpoint-up(md){
            display: flex !important;
        }

        li{
            font-family: 'CocoSharp L';
            list-style-type: none;
            padding: 7px 18px 8px 18px;
            font-size: 18px;
            color: $darkblue;
            border-radius: 20px;
            transition: all 0.2s ease;

            &:hover{
                background-color: $green;
            }
        }
    }

    #navbar-mobile{
        display: block!important;

        @include media-breakpoint-up(md){
            display: none !important;
        } 
    }

    #lang{
        border: 1px solid $darkblue;
        background-color: $white;
        border-radius: 40px;
        padding: 8px;
        display: none !important;
        position: fixed;
        bottom: 0;
        left: 0;
        margin-left: 16px;
        margin-bottom: 42px;
        z-index: 9 !important;
        @include media-breakpoint-up(lg){
            margin-left: 30px; 
        }
    
        @include media-breakpoint-up(xxl){
            margin-left: 46px; 
        }

        @include media-breakpoint-up(md){
            display: flex !important;
        }

        li{
            font-family: 'CocoSharp L';
            list-style-type: none;
            padding: 7px 18px 8px 18px;
            font-size: 18px;
            color: $darkblue;
            border-radius: 20px;
            transition: all 0.2s ease;

            &:hover{
                background-color: $green;
            }
        }
    }
}


.navbar-left{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    text-transform: uppercase;
  }
  
  .menu-button{
    display: flex;
    column-gap: 1rem;
    color: var(--white);
    cursor: pointer;
  }
  
  .navbar-left span{
    font-weight: 700;
  }
  
  #navbar a:hover {
    color: var(--highlight);
  }
  
  #navbar a.active {
    color: $lightblue;
  }
  
  .full_menu{
    width: 70%;
    padding: 30px 30px 20px 30px;
    top: 100px;
    left: 20px;
    position: fixed;
    background: $white;
    border: 1px solid $darkblue;
    border-radius: 15px;
    z-index: 98;
  }
  
  .full-menu-inner li, .full-menu-inner a {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    list-style: none;
    padding-bottom: 8px;
    color: $darkblue;
  }
  
  .hidden {
    display: none;
  }
  
  .visible {
    display: flex;
  }
  
  .mob-navbar-right {
    display: none;
  }
  
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  
  .wrapper_menu {
    width: 44px;
    height: 44px;
    border: 1px solid $darkblue;
    background-color: $white;
    border-radius: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: transform 630ms ease-out;
  }
  
  .wrapper_menu.open { 
    background-color: $darkblue;
  }
  
  .line_menu {
    background-color: $darkblue;
    border-radius: 1px;
    width: 90%;
    margin: 2.5px auto;
    height: 1px;
  }

  .line_menu.open{
    background-color: $white;
    transform: rotate(45deg); 
  }
  
  .line_menu.open:nth-child(1) {
    transform: rotate(45deg) translate(6px, 4px);
  }
    
  .line_menu.open:nth-child(3) {
    transform: rotate(-45deg) translate(4px, -4px);
  }
  

/* WA button */
.wa{
    margin-bottom: 42px;
    margin-right: 16px;
    z-index: 9 !important;

    img{
        margin-left: 20px;
    }

    @include media-breakpoint-up(lg){
        margin-right: 30px; 
        z-index: 1 !important;
    }

    @include media-breakpoint-up(xxl){
        margin-right: 1vw; 
    }
}

.wa-txt{
    display: none;
    transition: ease all 2s;
}

.wa-img:hover + .wa-txt{
    display: contents;
    font-weight: 600;
    color: $green;
    font-size: 20px;
    position: relative;
    z-index: 4;
}


/* Footer */
footer{
    background-color: $lightblue;
    border-radius: 15px;
    padding: 40px 20px;
    color: $white;
    margin-bottom: 40px;

    @include media-breakpoint-up(md){
        padding: 60px 80px 40px 80px;
        border-radius: 30px;
    }

    @include media-breakpoint-up(lg){
        padding: 30px 90px 30px 20px;
        border-radius: 40px;
    }

    li{
        list-style: none;
        font-size: 15px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md){
            font-size: 18px;
        }
    }

    p, a{
        font-size: 15px;
        margin-bottom: 0;
        word-wrap: break-word;
        

        @include media-breakpoint-up(md){
            font-size: 18px;
        }
    }
}

.footer{
    &-logo{
        width: 100%;
        

        @include media-breakpoint-up(lg){
            width: 30%;
        }
        
        &-inner{

            img{
                width: 120px;

                @include media-breakpoint-up(md){
                    width: 130px;
                }
            }
        }
    }
    

    &-nav{
        width: 100%;
        margin-top: 80px;

        li{
            list-style: none;
            font-size: 15px;
            line-height: 30px;
            padding-bottom: 8px;
            word-break: break-word;

            @include media-breakpoint-up(md){
                font-size: 16px;
                line-height: 24px;
            }

        }

        &-sm{
            margin-top: 38px;
            font-size: 11px;
            line-height: normal;
            position: relative;
            z-index: 2;

            p{
                font-size: 10px !important;
                line-height: 15px; 
            }

            @include media-breakpoint-up(md){
                font-size: 16px;
                margin-top: 38px;
            }

            .credit{
                font-size: 11px;
                &:hover {
                    text-decoration: underline;
                }
                @include media-breakpoint-up(md){
                    font-size: 14px;
                }
            }
        }

        &-col1{
            width: 30%;
        }

        &-col2{
            width: 30%;
        }

        &-col3{
            width: 40%;
        }

        @include media-breakpoint-up(lg){
            width: 70%;
            margin-top: 0;
        }
    }
}

/*Home*/
.home{
    &-hero{
        &-content{
            margin-top: 232px;

            @include media-breakpoint-up(md){
                margin-top: 310px;
            }

            &-mobile{
                display: flex;

                @include media-breakpoint-up(md){
                    display: none;
                } 
            }

        }

        &-txt{
            padding-right:0;

            @include media-breakpoint-up(lg){
                padding-right:4rem;
            }

            @include media-breakpoint-up(xl){
                padding-right:29px;
            }

            @include media-breakpoint-up(xxl){
                padding-right:100px;
            }

        }

        &-svg{
            width: 160px;
            margin-right: -50px;
            scale: 0;

            @include media-breakpoint-up(md){
                scale: 0.9;
                margin-right: -10px;
            }

            @include media-breakpoint-up(lg){
                scale: 1;
                margin-right: -60px;
                width: 190px;
            }

            @include media-breakpoint-up(xxl){
                margin-right: 0px;
                width: 190px;
            }
        }

        &-img{
            border-radius: 20px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;
            margin-top: 6px;
            aspect-ratio: 1/0.81;

            
            @include media-breakpoint-up(lg){
                height: 100%;
                margin-top: 22px;
                aspect-ratio: 2.5714/1;
            }
        }
    }

    &-about{
        margin-top: 0px;

        @include media-breakpoint-up(md){
            margin-top: 122px;
        }


        figure{
            width: 100%;
            overflow: hidden;
            object-fit: cover;
            border-radius: 210px 210px 0 0;
            margin: 0 0 0 0 !important;
            aspect-ratio: 0.7152/1;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        

        &-content{
            margin-top: 40px;
            padding-left: 0px;


            @include media-breakpoint-up(md){
                margin-bottom: 0px;
                margin-top: 0px;
            }

            @include media-breakpoint-up(lg){
                margin-top: 0;
                padding-left: 5px;
            }
        }

        &-txt{
            margin-top: 16px;

            @include media-breakpoint-up(md){
                margin-top: 35px;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg){
                font-size: 20px;
            }
        }
    }

    &-estrategia{
        margin-top: 60px;

        @include media-breakpoint-up(md){
            margin-top: 100px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 120px;
        }

        &-txt{
            margin-top: 45px;
        }
        
        .small{
            font-size: 15px;
            line-height: 24px;

            @include media-breakpoint-up(lg){
                font-size: 16px !important;
                line-height: 25px;
            }
        }
    }

    &-cta{
        margin-top: 110px;
        border: 1px solid $darkblue;
        background-color: $white;
        border-radius: 20px;
        padding: 64px;
        color: $darkblue;
        cursor: pointer;
        transition: all 0.2s ease;
        display: none !important;

        @include media-breakpoint-up(md){
            display: flex !important;
        }


        &-heading{
            font-size: 48px;

            @include media-breakpoint-up(lg){
                font-size: 80px;
            }

        }

        img{
            max-width: 80px;

            @include media-breakpoint-up(lg){
                max-width: 180px;
            }
        }

        &:hover{
            background-color: $pink;
        }
    }

    &-contact{
        margin-top: 80px;

        @include media-breakpoint-up(md){
            margin-top: 110px;
            margin-bottom: 110px;
        }


        &-img{
            img {
                border-radius: 0 150px 0 0;
                width: 100%;
                overflow: hidden;
                object-fit: cover;

                @include media-breakpoint-up(md){
                    border-radius: 0 200px 0 0;
                }
            }
        }

        &-txt{
            margin-top: 40px;

            @include media-breakpoint-up(lg){
                margin-top: 80px;
            }

            @include media-breakpoint-up(lg){
                margin-top: 0;
            }

            p{
                @include media-breakpoint-up(lg){
                    margin-bottom: 54px;
                }
            }

            span{
                font-size: 16px;
            }
        }

        &-form{
            margin-top: 40px;

            @include media-breakpoint-up(lg){
                margin-top: 20px;
            }
        }
    }

    &-quote{
        margin-top: 80px;
        text-align: center;

        @include media-breakpoint-up(md){
            margin-top: 110px;
        }

        p{
            font-size: 18px;

            @include media-breakpoint-up(md){
                font-size: 22px;
            }
        }
    }

    &-numbers{
        margin-top: 77px;
        background-color: $gray;
        border-radius: 20px;
        padding: 48px 30px;
        text-align: center;

        @include media-breakpoint-up(md){
            margin-top: 110px;
            padding: 72px 0;
        }

        &-countup{
            margin: 40px auto 0 auto;
            text-align: center;

            @include media-breakpoint-up(md){
                margin: 80px auto 0 auto;
            }

            @include media-breakpoint-up(lg){
                margin: 70px auto 0 auto;
            }

            h2{
                font-size: 40px;
                line-height: 54px;

                @include media-breakpoint-up(md){
                    font-size: 80px;
                }

                @include media-breakpoint-up(lg){
                    font-size: 80px;
                    line-height: 70px;
                }
                
                span{
                    color: $green;
                }
            }

            &-cuentas{
                margin-top: 32px;

                @include media-breakpoint-up(md){
                    margin-top: 80px;
                }

                @include media-breakpoint-up(lg){
                    margin-top: 0px;
                }
            }

            p{
                margin-bottom: 0;
                font-size: 18px;

                @include media-breakpoint-up(md){
                    margin-top: 20px;
                    font-size: 20px;
                }
            }
        }

        &-btn{
            margin-top: 40px;

            @include media-breakpoint-up(lg){
                margin-top: 120px;
            }
        }
    }

    &-image{
        margin-top: 80px;
        

        @include media-breakpoint-up(md){
            margin-top: 110px;
        }
        
        img{
            width: 100%;
            overflow: hidden;
            border-radius: 0 440px 440px 0;
            object-fit: cover;
            aspect-ratio: 1.25/1;

            @include media-breakpoint-up(lg){
                aspect-ratio: 2.12/1;
            }
        }
    }

    &-slider{
        margin: 80px auto 0 auto;

        @include media-breakpoint-up(md){
            margin: 110px auto 0 auto;
        }


        &-container{
            position: relative;
            width: 100%;
            overflow: hidden;
        }

        &-nav{
            border: 1px solid $darkblue;
            border-radius: 200px;
            padding: 2px 10px;
            height: 48px;
            width: 48px;

            @include media-breakpoint-up(lg){
                height: 70px;
                width: 80px;
                padding: 10px 15px;
            }
        }

        &-wrapper{
            border-radius: 20px;
            margin: 0;
            overflow: hidden;
            width: 100%;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -khtml-border-radius: 20px;

            @include media-breakpoint-up(lg){
                margin: 0 30px;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -khtml-border-radius: 50px;
            }

            @include media-breakpoint-up(xl){
                margin: 0 34px;
            }

        }

        &-inner{  
            padding: 20px;
            font-size: 18px;
            line-height: 23px;
            height: 100%;
            min-width: 85%;

            @include media-breakpoint-up(md){
                font-size: 40px;
                line-height: 41px;
                padding: 65px;
            }

            @include media-breakpoint-up(lg){
                padding: 40px 32px;
            }

            p, span {
                font-size: 18px;
                line-height: 23px;

                @include media-breakpoint-up(md){
                    font-size: 40px;
                    line-height: 41px;
                }

                @include media-breakpoint-up(lg){
                    font-size: 26px;
                }

            }

            .author{
                font-weight: 300;
                font-size: 16px;
                line-height: 28px;
                font-weight: 600;
                margin-top: 82px;

                @include media-breakpoint-up(lg){
                    margin-top: 80px;
                }
            }
        }
    }

    &-divisas{
        margin: 80px 0 40px 0;
        text-align: center;
        @include media-breakpoint-up(lg){
            margin: 110px 0 80px 0;  
        }
        span{
            font-size: 11px;
            line-height: 15px;
            display: block;
            margin-top: 20px;
            @include media-breakpoint-up(md){
                margin: 20px 0;
                font-size: 12px;
                line-height: 16px;
            }
            @include media-breakpoint-up(lg){
                margin: 20px 0;
                font-size: 12px;
                line-height: 16px;
            }
        }
        &-mobile{
            display: block;
            @include media-breakpoint-up(md){
                display: none;
            }
        }

    }

    &-mobile{
        display: block;

        @include media-breakpoint-up(md){
            display: none !important;
        }

        &-cta{
            border: 1px solid $darkblue;
            background-color: $white;
            border-radius: 15px;
            padding: 18px 22px;
            color: $darkblue;
            cursor: pointer;
            transition: all 0.2s ease;
    
    
            &-heading{
                font-size: 30px;
            }
    
            img{
                max-width: 40px;
            }
        }
    }

    &-desktop{
        display: none !important;

        @include media-breakpoint-up(md){
            display: flex !important;
        }
    }
} 

.swiper-wrapper{
    padding-left: 2.5%;
    @include media-breakpoint-up(lg){
        padding-left: 3.5%;  
    }
}

.swiper-pagination-d{
    font-size: 12px !important;
    padding-left: 1.5vw;
}

.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
  }
  
  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .slide {
    flex: 0 0 100%;
  }
  
  .active {
    opacity: 1;
  }
  
  .home-slider-nav {
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

#slider-prev{
    transform: translateX(0%);
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover{
        transform: translateX(-10%);
    }  
}

#slider-next{
    transform: translateX(0%);
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover{
        transform: translateX(10%);
    }  
}

.swiper-slide {
    width: 80%;
    
  }
  
  .swiper-slide:nth-child(2n) {
    width: 80%;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 80%;
  }
  

/* About */
.about{

    &-hero{
        margin-top: 95px;

        @include media-breakpoint-up(md){
            margin-top: 115px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 166px;
        }

        figure{
            aspect-ratio:  2.5778/2.08;
            overflow: hidden;
            border-radius: 20px;
            @include media-breakpoint-up(lg){
                aspect-ratio:  2.5778/1;
            }
        
            img{
                width: 100%;
                object-fit: cover;
                margin-top: 0;
            }
        }
    }

    &-intro{
        margin-top: 38px;

        @include media-breakpoint-up(md){
            margin-top: 60px;
        }

    }

    &-txt{
        margin-top: 12px;
        font-size: 15px;
        line-height: 20x;
        margin-bottom: 0;

        @include media-breakpoint-up(md){
            margin-top: 45px;
            font-size: 20px;
            line-height: 25px;
        }
    }

    &-img{
        img{
            max-width: 180px;
            width: 180px;
            object-fit: cover;


        @include media-breakpoint-up(lg){
            max-width: 304px;
            width: 304px;
        }
        }

        figure {
            margin-top: 40px;
            @include media-breakpoint-up(md){
                margin-top: 0; 
            }
                svg {
                    width: 160px;
                    height: 160px;

                @include media-breakpoint-up(md){
                    width: 200px;
                    height: 200px;  
                }
            }
        }
        
    }
    
    &-values{
        margin: 80px auto 0 auto;

        @include media-breakpoint-up(md){
            margin: 110px auto 0 auto;
        }

        @include media-breakpoint-up(lg){
            margin: 120px auto 0 auto;
        }


        &-mission{
            background-color: $lightblue;
            border-radius: 45px;
            padding: 50px 30px;
            text-align: center;

            @include media-breakpoint-up(md){
                padding: 15px 30px;
                border-radius: 175px;
                text-align: left;
            }

            @include media-breakpoint-up(lg){
                padding: 30px 60px;
            }

            @include media-breakpoint-up(xxñ){
                padding: 60px 90px;
            }

            p{
                font-size: 18px;
                line-height: 23px;
                font-weight: 500;
                margin-bottom: 0;
                margin-top: 16px;
            }

            &-text{

                @include media-breakpoint-up(md){
                    border-left: 1px solid $black;
                    padding: 10px 25px 10px 35px;
                }
    
                @include media-breakpoint-up(lg){
                    border-left: 1px solid $black;
                    padding: 20px 50px 20px 70px;
                }
            }
        }


        &-vision{
            background-color: $pink;
            border-radius: 45px;
            padding: 50px 30px;
            text-align: center;
            margin-top: 19px;

            @include media-breakpoint-up(md){
                padding: 15px 30px;
                border-radius: 175px;
                text-align: left;
                margin-top: 40px;
            }

            @include media-breakpoint-up(lg){
                padding: 30px 60px;
            }

            p{
                font-size: 18px;
                line-height: 23px;
                font-weight: 500;
                margin-bottom: 0;
                margin-top: 16px;
            }

            &-text{

                @include media-breakpoint-up(md){
                    border-left: 1px solid $black;
                    padding: 10px 25px 10px 35px;
                }
    
                @include media-breakpoint-up(lg){
                    border-left: 1px solid $black;
                    padding: 20px 50px 20px 70px;
                }
            }
        }

        &-marquee {
            margin-top: 40px;
            padding-bottom: 10px;
            text-align: center;

            @include media-breakpoint-up(md){
                margin-top: 110px;
                text-align: left;
            }


            &-txt{
                padding-right: 56px;

                h3{
                    word-spacing: 56px;
                    font-size: 18px;

                    @include media-breakpoint-up(md){
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(lg){
                        font-size: 24px;
                    }
                }
            }
        }
    }

    &-values-marquee{
        padding-left: 0px;

        @include media-breakpoint-up(md){
            padding-left: 30px;
        }
    }

    &-parallax{
        margin-top: 69px;

        @include media-breakpoint-up(md){
            margin-top: 110px;
        }
        
        
        img{
            width: 100%;
            overflow: hidden;;
            border-radius: 440px 0 0 440px;
            object-fit: cover;
            aspect-ratio: 2.12/1.7;

            @include media-breakpoint-up(lg){
                aspect-ratio: 2.12/1;
            }

            @include media-breakpoint-up(xxl){
                aspect-ratio: 2.3/1;
            }
        }
    }

    &-brandbook{
        margin: 81px auto;

        @include media-breakpoint-up(md){
            margin: 110px auto;
        }

        &-btn{
            @include media-breakpoint-up(md){
                margin-top: 40px;
                margin-left: 46px;
            }
        }

        &-txt{
            padding-left: 0px;
            margin-top: 46px;
            margin-bottom: 40px;

            @include media-breakpoint-up(md){
                padding-left: 32px;
                margin-top: 0;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg){
                padding-left: 0;
            }
        }
    }
}

/*Estrategias*/
.estrategias{
    &-hero{
        margin-top: 95px;

        @include media-breakpoint-up(md){
            margin-top: 115px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 165px;
        }

        figure{
            aspect-ratio:  2.5778/2.08;
            overflow: hidden;
            border-radius: 20px;
            @include media-breakpoint-up(lg){
                aspect-ratio:  2.5778/1;
            }
        
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-top: 0;
            }
        }
    }

    &-intro{
        margin-top: 38px;

        @include media-breakpoint-up(md){
            margin-top: 60px;
        }

        &-btn{
            margin-top: 40px;
        }
    }

    &-svg{
        img{
           width: 100%;
        }
    }

    &-contact{
        margin-top: 82px;
        @include media-breakpoint-up(md){
            margin-top: 110px;
        }

        img {
            border-radius: 0 200px 0 200px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;

            @include media-breakpoint-up(lg){
                border-radius: 0 100px 0 100px;
            }
        }
    }

    &-w{
        margin: 81px auto;
        border-radius: 40px 40px 0px 200px;
        background-color: $pink;
        overflow: hidden;
        @include media-breakpoint-up(lg){
            margin: 110px auto;
            border-radius: 20px 0 200px 40px;
        }

        &-txt{
            padding: 40px 20px 23px 20px;
            @include media-breakpoint-up(md){
                padding: 30px;
            }

            p{
                margin-top: 16px;
                @include media-breakpoint-up(md){
                    margin-top: 40px;
                }
            }

            .small{
                font-size: 15px;
                line-height: 20px;
            }
        }

        &-img{
            overflow: hidden;
            img{
                flex-shrink: 0;
                width: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-up(md){
                scale: 1;
                height: auto;
                aspect-ratio: 2/1.8;
            }

            @include media-breakpoint-up(lg){
                aspect-ratio: 1/1.65;
            }

            @include media-breakpoint-up(xl){
                aspect-ratio: 1/1;
            }

            @include media-breakpoint-up(xxl){
                aspect-ratio: 1.4/1.1;
            }
        }
    }
}

/* Contact */
.contact{
    &-intro{
        margin-top: 80px;

        @include media-breakpoint-up(md){
            margin-top: 140px;
        }

        &-svg{
            max-width: 205px;
        }

        h2{
            margin-bottom: 40px;
        }

        h3{
           line-height: 28px;
        }

        p{
            font-size: 16px;
            margin-bottom: 0;
        }

        li{
            list-style: none;
            color: $darkblue;
            font-size: 18px;
            padding-bottom: 18px;

            &:hover{
                color: $lightblue;
            }
        }


        &-inner{
            margin-top: 40px;

            @include media-breakpoint-up(md){
                margin-top: -20px;
            }
            .small{
                font-size: 15px;
                line-height: 20px;

                @include media-breakpoint-up(md){
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }

    &-map{
        margin: 80px auto;
        border-radius: 20px;
        overflow: hidden;

        @include media-breakpoint-up(md){
            margin: 110px auto;
        }

    }

    &-socials{
        margin-top: 40px;

        @include media-breakpoint-up(md){
            margin-bottom: 0px;
        }

        li{
            list-style-type: none;
            font-size: 18px;
            color: $darkblue;
            margin-bottom: 12px;

            &:last-of-type{
                margin-bottom: 0px;
            }

            &:hover{
                color: $lightblue;
            }
        }
    }
}

/*Login */
.login{
    &-wrapper{
        margin: auto;

        h3{
            margin-bottom: 20px;
        }

        .form-input{
            margin-bottom: 20px;
        }
    }
}

/* Estatuto de riesgo */
.estatuto{
    &-riesgo{
        margin: 248px auto 184px auto;
    }
}

/* Calculadora */
.calculadora{
    margin: 80px auto 80px auto;

    @include media-breakpoint-up(lg){
        margin: 150px auto 80px auto; 
    }

    &-title{
        margin-top: 60px;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg){
            margin-top: 0;
            margin-bottom: 92px;
        }
    }

    .input-group{
        border: 1px solid $darkblue;
        outline: none;
        border-radius: 50px;
    }

    .input-group-text{
        border-radius: 50px; 
        background-color: $white;
    }

    input{
        border: none;
        outline: none;
        border-radius: 50px;
    }

    p{
        font-size: 16px;
    }
    
    &-results{
        margin-top: 20px;

        @include media-breakpoint-up(lg){
            margin-top: 150px;
        }
    }

    &-badges{
        margin-top: 40px;
        text-align: center;

        .wrapper{
            margin-bottom: 20px;

            @include media-breakpoint-up(lg){
                margin-bottom: 0;
               }
        }

        .blue{
           background-color: $lightblue; 
           border-radius: 80px;
           padding: 18px;
           min-width: 210px;
           font-size: 20px;
           margin-bottom: 2px;

           @include media-breakpoint-up(lg){
            margin-bottom: 18px;
            font-size: 26px;
            min-width: 165px;
           }

           @include media-breakpoint-up(xl){
            min-width: 210px;
           }
        }

        .pink{
            background-color: $pink;
            border-radius: 80px;
            padding: 18px;
            min-width: 210px;
            font-size: 20px;
            margin-bottom: 20px;
            @include media-breakpoint-up(lg){
                font-size: 26px;
                min-width: 165px;
           }

           @include media-breakpoint-up(xl){
            min-width: 210px;
           }
        }

        .green{
            background-color: $green;
            border-radius: 80px;
            padding: 18px;
            min-width: 210px;
            font-size: 20px;
            margin-bottom: 20px;
            @include media-breakpoint-up(lg){
                font-size: 26px;
                min-width: 165px;
           }

           @include media-breakpoint-up(xl){
            min-width: 210px;
           }
        }

        .grey{
            background-color: $gray;
            border-radius: 80px;
            padding: 18px;
            min-width: 210px;
            font-size: 20px;
            margin-bottom: 20px;
            @include media-breakpoint-up(lg){
                font-size: 26px;
                min-width: 165px;
           }

           @include media-breakpoint-up(xl){
            min-width: 210px;
           }
        }

    }

    table{
        text-align: center;
        font-size: 10px;
        overflow-x: scroll !important;
        @include media-breakpoint-up(md){
            font-size: 14px;
        }
        @include media-breakpoint-up(lg){
            font-size: 16px;
        }
    }

    thead{
        padding: 20px;
        @include media-breakpoint-up(md){
            padding: 40px;
        }
        @include media-breakpoint-up(lg){
            padding: 60px;
        }
    }

    th{
        padding: 10px 10px;
        height: 60px;

        @include media-breakpoint-up(md){
            padding: 20px 40px;
            height: 70px;
        }
    }

    tbody, td, tfoot, th, thead, tr{
        vertical-align: middle;
    }
}

/* Brandbook */
.brandbook{
    margin-bottom: 110px;

    &-content{
        margin-top: 40px;

        @include media-breakpoint-up(md){
            margin-top: 136px; 
        }

        p{
            margin-bottom: 10px;
        }

        .small{
            font-size: 13px;
            line-height: 15px;

            @include media-breakpoint-up(md){
                font-size: 16px;
                line-height: 24px; 
            }
        }
    }

    h4{
        font-size: 18px;
        @include media-breakpoint-up(md){
            margin-bottom: 30px;
            font-size: 20px;
        }
    }

    li{
        list-style: none;
        margin-bottom: 20px;
        color: $darkblue;
        font-size: 18px;

        &:hover{
            color: $lightblue;
        }
    }

    &-sidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    z-index: 4;
    }

    .vision {
    border-radius: 0 200px 0 0;
    }

    .look{
    border-radius: 0 0 0 0;
    }
    
     &-img{
        border-radius: 20px;
        width: 100%;
        overflow: hidden;
        object-fit: cover;
        margin-top: 15px;

        @include media-breakpoint-up(md){
            height: 100%;
            margin-top: 40px;
        }
        @include media-breakpoint-up(lg){
            height: 100%;
            margin-top: 40px;
        }
    }
}

.btn-primary{
    background-color: $white !important;
    color: $darkblue !important;
    padding: 0 !important;
    border: 0 !important;
    width: 100%;
}

.dropdown-menu{
    border: 0 !important;
    padding: 10px 0 !important;
    position: relative !important;
    transform: translate3d(0px, 0px, 0px) !important;
}

.dropdown-item{
    padding: 10px 0 !important;
    color: $darkblue !important;
}

.dropdown-toggle::after{
    background-image: url('data:image/svg+xml;utf8,<svg width="19" height="11" viewBox="0 0 19 11" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L9.5 10L18 1" stroke="%23001A64" fill="none" stroke-miterlimit="10" stroke-linecap="square"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 11px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-left: 14em !important;
}

/* Legales */
.legals{
    margin: 150px auto 60px auto;

    ul li::marker{
        font-size: 10px;
    }

    a{
        text-decoration: underline;
    }

    p{
        font-size: 11px;
        line-height: 15px;
    
        @include media-breakpoint-up(md){
            font-size: 16px !important;
            line-height: 25px;
        }
    }

    h5{
        font-size: 13px;
        line-height: 17px;
        text-transform: uppercase;
        @include media-breakpoint-up(md){
            font-size: 22px;
            line-height: 30px;  
            text-transform: none;
        }
    }
}

/*Spacing*/
.mt-10{
    margin-top: 10px;
}

.mt-15{
    margin-top: 15px;
}

.mt-17{
    margin-top: 17px;
}

.mt-20{
    margin-top: 20px;
}

.mmt-20{
    margin-top: 20px;

    @include media-breakpoint-up(md){
        margin-top: 0;
    }
}

.mt-30{
    margin-top: 30px;
}

.mt-35{
    margin-top: 35px;
}

.mt-md-35{
    @include media-breakpoint-only(md){
        margin-top: 35px;
    }
}

.mt-md-40{
    @include media-breakpoint-only(md){
        margin-top: 40px;
    }
}

.mt-40{
    margin-top: 40px;
}

.mt-50{
    margin-top: 50px;
}

.mt-60{
    margin-top: 60px;
}

.mt-md-60{
    @include media-breakpoint-only(md){
        margin-top: 60px;
    }
}

.mt-70{
    margin-top: 70px;
}

.mt-80{
    margin-top: 80px;
}

.mt-96{
    margin-top: 96px;
}

.mt-100{
    margin-top: 100px;
}

.mt-110{
    margin-top: 110px;
}

.mt-120{
    margin-top: 120px;
}

.mt-md-0{
    @include media-breakpoint-up(md){
        margin-top: 0px !important; 
    }
}

.mt-md-80{
    
    @include media-breakpoint-up(md){
        margin-top: 80px; 
    }
}

.mt-lg-30{
    @include media-breakpoint-up(lg){
        margin-top: 30px; 
    }
}

.mt-md-40{
    @include media-breakpoint-up(md){
        margin-top: 40px; 
    }
}

.mt-md-60{
    @include media-breakpoint-up(md){
        margin-top: 60px; 
    }
}

.mt-md-80{
    @include media-breakpoint-up(md){
        margin-top: 80px; 
    }
}

.mt-lg-80{
    margin-top: 80px; 
}

.mb-28{
    margin-bottom: 28px;
}

.mb-lg-40{
    @include media-breakpoint-up(lg){
        margin-bottom: 40px;
    }
}

.mb-50{
    margin-bottom: 50px;
}

.mb-lg-50{
    margin-bottom: 50px;
}

.mb-lg-0{
    @include media-breakpoint-up(lg){
        margin-bottom: 0 !important;
    }
}

.mb-80{
    margin-bottom: 80px;
}

.mb-100{
    margin-bottom: 100px;
}

.m-auto{
    margin: auto;
}

.px-0{
    padding-left: 0;
    padding-right: 0;
}

.pt-20{
    padding-top: 20px;
}


.pt-35{
    padding-top: 35px;
}

.pt-80{
    padding-top: 80px;
}

.pt-md-20{
    @include media-breakpoint-up(md){
        padding-top: 20;
    }
}

.pt-lg-35{
    @include media-breakpoint-up(lg){
        padding-top: 35px;
    }
}

.pl-10{
    padding-left: 10px;
}

.pl-20{
    padding-left: 20px;
}

.pl-30{
    padding-left: 30px;
}

.pl-md-30{
    @include media-breakpoint-up(md){
        padding-left: 30px;
    }
}


.pb-11{
    padding-bottom: 11px;
}


.pb-20{
    padding-bottom: 20px;
}

.pb-80{
    padding-bottom: 80px;
}

.pb-md-0{
    @include media-breakpoint-up(md){
        padding-bottom: 0px;
    }
}

.small-brandbook{
    p{
        font-size: 13px;
        line-height: 15px;
        margin-top: 30px;

        @include media-breakpoint-up(md){
            font-size: 16px;
            line-height: 24px; 
        }
    }
}